import React from 'react';
import { useAppContext } from '../../context/AppContext';
import { useTasks } from '../../hooks/useTasks';
import { Droppable, Draggable } from '@hello-pangea/dnd';
import TaskItem from '../tasks/TaskItem';
import { format, parseISO, isAfter, startOfDay } from 'date-fns';

function ScheduledTasksPanel({ onTaskSelect, onTaskDelete, showEmptyItem }) {
  const { projects, inboxTasks } = useAppContext();
  const { toggleTaskComplete, toggleTodayTask } = useTasks();

  // Collect all tasks with due dates
  const allTasks = [
    ...inboxTasks.map(task => ({ ...task, projectName: 'Inbox' })),
    ...projects.flatMap(project => project.tasks.map(task => ({ ...task, projectName: project.name })))
  ];

  // Filter and sort tasks with due dates
  const scheduledTasks = allTasks
    .filter(task => task.dueDate && !task.completed)
    .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));

  const renderEmptyItem = () => (
    <div className="bg-dark-bg p-3 rounded cursor-pointer hover:bg-dark-muted transition-colors h-12"></div>
  );

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = parseISO(dateString);
    return format(date, "MMM dd, yyyy");
  };

  const isOverdue = (date) => {
    if (!date) return false;
    const now = startOfDay(new Date());
    const taskDate = parseISO(date);
    return isAfter(now, taskDate);
  };

  return (
    <Droppable droppableId="scheduled">
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className={`space-y-2 min-h-[100px] ${snapshot.isDraggingOver ? 'bg-dark-primary bg-opacity-20' : ''}`}
        >
          {scheduledTasks.length > 0 ? (
            scheduledTasks.map((task, index) => (
              <Draggable key={task._id} draggableId={task._id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <TaskItem 
                      task={{
                        ...task,
                        name: `${task.name} (${task.projectName})`
                      }}
                      onSelect={() => onTaskSelect(task)}
                      onToggleComplete={toggleTaskComplete}
                      onDelete={() => onTaskDelete(task._id)}
                      onToggleToday={toggleTodayTask}
                      isDragging={snapshot.isDragging}
                      leftContent={formatDate(task.dueDate)}
                      showCheckbox={false}
                      isOverdue={isOverdue(task.dueDate)}
                    />
                  </div>
                )}
              </Draggable>
            ))
          ) : showEmptyItem ? (
            renderEmptyItem()
          ) : (
            <p className="text-center text-dark-muted">No scheduled tasks.</p>
          )}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

export default ScheduledTasksPanel;