import { useCallback } from 'react';
import { useAppContext } from '../context/AppContext';
import * as api from '../services/api';

export const useDragAndDrop = () => {
  const { 
    inboxTasks, 
    setInboxTasks, 
    projects,
    setProjects, 
    setTodayTasks,
    orderedTodayTasks,
    setOrderedTodayTasks,
    setError 
  } = useAppContext();

  const onDragEnd = useCallback(async (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    // Handle reordering within the Today panel
    if (source.droppableId === 'today' && destination.droppableId === 'today') {
      const newOrderedTasks = Array.from(orderedTodayTasks);
      const [reorderedItem] = newOrderedTasks.splice(source.index, 1);
      newOrderedTasks.splice(destination.index, 0, reorderedItem);
      
      setOrderedTodayTasks(newOrderedTasks);

      try {
        await api.updateTodayTasksOrder(newOrderedTasks);
      } catch (err) {
        setError('Failed to update today tasks order. Please try again.');
      }
      return;
    }

    // Handle dragging to/from Today panel
    if (source.droppableId === 'today' || destination.droppableId === 'today') {
      const taskId = draggableId.replace('today-', '');
      if (destination.droppableId === 'today') {
        // Adding to Today
        if (!orderedTodayTasks.includes(taskId)) {
          setOrderedTodayTasks(prev => [...prev, taskId]);
          const task = inboxTasks.find(t => t._id === taskId) || 
                       projects.flatMap(p => p.tasks).find(t => t._id === taskId);
          if (task) {
            setTodayTasks(prev => [...prev, task]);
          }
        }
      } else {
        // Removing from Today
        setOrderedTodayTasks(prev => prev.filter(id => id !== taskId));
        setTodayTasks(prev => prev.filter(task => task._id !== taskId));
      }

      try {
        await api.toggleTodayTask(taskId);
      } catch (err) {
        setError('Failed to update today task. Please try again.');
      }
      return;
    }

    // Handle other drag and drop operations (existing logic)
    const updateState = () => {
      if (source.droppableId === 'inbox' && destination.droppableId !== 'inbox') {
        // Moving from inbox to a project
        const taskToMove = inboxTasks.find(task => task._id === draggableId);
        setInboxTasks(prevTasks => prevTasks.filter(task => task._id !== draggableId));
        setProjects(prevProjects => 
          prevProjects.map(project => 
            project._id === destination.droppableId
              ? { ...project, tasks: [...project.tasks, taskToMove] }
              : project
          )
        );
      } else if (source.droppableId !== 'inbox' && destination.droppableId === 'inbox') {
        // Moving from a project to inbox
        setProjects(prevProjects => {
          const updatedProjects = prevProjects.map(project => {
            if (project._id === source.droppableId) {
              const taskToMove = project.tasks.find(task => task._id === draggableId);
              setInboxTasks(prevInboxTasks => {
                const taskExists = prevInboxTasks.some(task => task._id === taskToMove._id);
                if (!taskExists) {
                  return [...prevInboxTasks, taskToMove];
                }
                return prevInboxTasks;
              });
              return {
                ...project,
                tasks: project.tasks.filter(task => task._id !== draggableId)
              };
            }
            return project;
          });
          return updatedProjects;
        });
      } else if (source.droppableId !== 'inbox' && destination.droppableId !== 'inbox') {
        // Moving between projects or reordering within a project
        setProjects(prevProjects => {
          return prevProjects.map(project => {
            if (project._id === source.droppableId) {
              const newTasks = Array.from(project.tasks);
              const [movedTask] = newTasks.splice(source.index, 1);
              
              if (project._id === destination.droppableId) {
                // Reordering within the same project
                newTasks.splice(destination.index, 0, movedTask);
              }
              
              return {
                ...project,
                tasks: newTasks
              };
            }
            
            if (project._id === destination.droppableId && source.droppableId !== destination.droppableId) {
              // Moving task to a different project
              const taskToMove = prevProjects
                .find(p => p._id === source.droppableId)
                .tasks.find(task => task._id === draggableId);
              const newTasks = Array.from(project.tasks);
              newTasks.splice(destination.index, 0, taskToMove);
              return {
                ...project,
                tasks: newTasks
              };
            }
            
            return project;
          });
        });
      } else {
        // Reordering within inbox
        setInboxTasks(prevInboxTasks => {
          const newInboxTasks = Array.from(prevInboxTasks);
          const [reorderedItem] = newInboxTasks.splice(source.index, 1);
          newInboxTasks.splice(destination.index, 0, reorderedItem);
          return newInboxTasks;
        });
      }
    };

    // Update state immediately
    updateState();

    // Then make the API call
    try {
      await api.reorderTasks(
        source.droppableId,
        destination.droppableId,
        draggableId,
        destination.index
      );
    } catch (err) {
      setError('Failed to reorder task. The view may be out of sync.');
      console.error(err);
    }
  }, [inboxTasks, projects, orderedTodayTasks, setInboxTasks, setProjects, setTodayTasks, setOrderedTodayTasks, setError]);

  return { onDragEnd };
};