import React, { useState, useEffect, useRef } from 'react';
import { AlertDialog, AlertDialogContent, AlertDialogHeader, AlertDialogTitle, AlertDialogDescription, AlertDialogFooter, AlertDialogCancel } from './alert-dialog';
import { Download, Upload, CheckCircle, File } from 'lucide-react';

function ImportExportModal({ isOpen, onClose, onExport, onImport, mode }) {
  const [file, setFile] = useState(null);
  const [status, setStatus] = useState('idle');
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setStatus('idle');
  };

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleAction = async () => {
    setStatus('loading');
    try {
      if (mode === 'export') {
        await onExport();
      } else {
        await onImport(file);
      }
      setStatus('success');
      setFile(null);
      setTimeout(() => {
        onClose();
        setStatus('idle');
      }, 2000);
    } catch (error) {
      setStatus('error');
      console.error(`${mode === 'export' ? 'Export' : 'Import'} failed:`, error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setStatus('idle');
      setFile(null);
    }
  }, [isOpen]);

  const renderContent = () => {
    if (mode === 'export') {
      return (
        <button
          onClick={handleAction}
          className="w-full flex items-center justify-center space-x-2 bg-dark-primary text-dark-bg py-2 px-4 rounded-md hover:bg-opacity-80 transition-colors"
          disabled={status === 'loading' || status === 'success'}
        >
          {status === 'success' ? (
            <>
              <CheckCircle size={20} />
              <span>Export Successful!</span>
            </>
          ) : status === 'loading' ? (
            <span>Exporting...</span>
          ) : (
            <>
              <Download size={20} />
              <span>Export Data</span>
            </>
          )}
        </button>
      );
    } else {
      return (
        <div className="space-y-2">
          <input
            type="file"
            accept=".json"
            onChange={handleFileChange}
            ref={fileInputRef}
            className="hidden"
          />
          <div className="flex items-center space-x-2">
            <button
              onClick={handleFileButtonClick}
              className="flex items-center justify-center space-x-2 bg-dark-bg text-dark-text py-2 px-4 rounded-md hover:bg-dark-hover transition-colors border border-dark-muted"
            >
              <File size={20} />
              <span>Choose File</span>
            </button>
            <span className="text-dark-text truncate">
              {file ? file.name : 'No file chosen'}
            </span>
          </div>
          <button
            onClick={handleAction}
            disabled={!file || status === 'loading' || status === 'success'}
            className="w-full flex items-center justify-center space-x-2 bg-dark-primary text-dark-bg py-2 px-4 rounded-md hover:bg-opacity-80 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {status === 'success' ? (
              <>
                <CheckCircle size={20} />
                <span>Import Successful!</span>
              </>
            ) : status === 'loading' ? (
              <span>Importing...</span>
            ) : (
              <>
                <Upload size={20} />
                <span>Import Data</span>
              </>
            )}
          </button>
        </div>
      );
    }
  };

  return (
    <AlertDialog open={isOpen} onOpenChange={onClose}>
      <AlertDialogContent className="bg-dark-surface border border-dark-muted">
        <AlertDialogHeader>
          <AlertDialogTitle className="text-xl font-semibold text-dark-text">
            {mode === 'export' ? 'Export Data' : 'Import Data'}
          </AlertDialogTitle>
          <AlertDialogDescription className="text-dark-muted">
            {mode === 'export' 
              ? 'Backup your data to a JSON file.' 
              : 'Restore your data from a previous backup.'}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <div className="my-4 space-y-4">
          {renderContent()}
          {status === 'error' && (
            <p className="text-red-500 text-sm">
              {mode === 'export' ? 'Export' : 'Import'} failed. Please try again.
            </p>
          )}
        </div>
        <AlertDialogFooter>
          <AlertDialogCancel className="bg-dark-bg text-dark-text border border-dark-muted hover:bg-dark-muted transition-colors">
            Close
          </AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default ImportExportModal;