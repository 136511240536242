import { useCallback } from 'react';
import { useAppContext } from '../context/AppContext';
import * as api from '../services/api';

export const useTasks = () => {
  const { 
    inboxTasks, 
    setInboxTasks,
    projects,
    setProjects,
    todayTasks,
    setTodayTasks,
    orderedTodayTasks,
    setOrderedTodayTasks,
    setError,
    fetchInboxTasks,
    fetchProjects,
    fetchTodayTasks
  } = useAppContext();

  const addTask = useCallback(async (taskName, taskDescription, taskDueDate) => {
    try {
      await api.addTask(taskName, taskDescription, taskDueDate);
      await fetchInboxTasks(); // Refresh inbox tasks after adding a new task
    } catch (err) {
      setError('Failed to add task. Please try again.');
    }
  }, [fetchInboxTasks, setError]);

  const updateTask = useCallback(async (taskId, updates) => {
    try {
      const updatedTask = await api.updateTask(taskId, updates);
      
      // Update inboxTasks
      setInboxTasks(prevTasks => 
        prevTasks.map(task => task._id === taskId ? updatedTask : task)
      );
      
      // Update projects
      setProjects(prevProjects => 
        prevProjects.map(project => ({
          ...project,
          tasks: project.tasks.map(task => task._id === taskId ? updatedTask : task)
        }))
      );
      
      // Update todayTasks
      setTodayTasks(prevTasks => 
        prevTasks.map(task => task._id === taskId ? updatedTask : task)
      );

      return updatedTask;
    } catch (err) {
      setError('Failed to update task. Please try again.');
    }
  }, [setInboxTasks, setProjects, setTodayTasks, setError]);

  const deleteTask = useCallback(async (taskId) => {
    try {
      await api.deleteTask(taskId);
      // Refresh all task lists to ensure consistency
      await Promise.all([fetchInboxTasks(), fetchProjects(), fetchTodayTasks()]);
      return true; // Indicate successful deletion
    } catch (err) {
      setError('Failed to delete task. Please try again.');
      return false; // Indicate failed deletion
    }
  }, [fetchInboxTasks, fetchProjects, fetchTodayTasks, setError]);

  const toggleTaskComplete = useCallback(async (taskId, completed) => {
    try {
      const updates = {
        completed,
        completedDate: completed ? new Date().toISOString() : null
      };
      await api.updateTask(taskId, updates);
      // Refresh all task lists to ensure consistency
      await Promise.all([fetchInboxTasks(), fetchProjects(), fetchTodayTasks()]);
    } catch (err) {
      setError('Failed to update task. Please try again.');
    }
  }, [fetchInboxTasks, fetchProjects, fetchTodayTasks, setError]);

  const updateTodayTasksOrder = useCallback(async (newOrder) => {
    try {
      await api.updateTodayTasksOrder(newOrder);
      setOrderedTodayTasks(newOrder);
    } catch (err) {
      setError('Failed to update today tasks order. Please try again.');
    }
  }, [setOrderedTodayTasks, setError]);

  const toggleTodayTask = useCallback(async (taskId) => {
    try {
      const updatedTask = await api.toggleTodayTask(taskId);
      
      if (updatedTask.isToday) {
        // Task added to Today
        setTodayTasks(prev => [...prev, updatedTask]);
        setOrderedTodayTasks(prev => [...prev, taskId]);
      } else {
        // Task removed from Today
        setTodayTasks(prev => prev.filter(task => task._id !== taskId));
        setOrderedTodayTasks(prev => prev.filter(id => id !== taskId));
      }

      // Update task in projects or inbox
      setProjects(prev => prev.map(project => ({
        ...project,
        tasks: project.tasks.map(task => 
          task._id === taskId ? { ...task, isToday: updatedTask.isToday } : task
        )
      })));
      setInboxTasks(prev => prev.map(task => 
        task._id === taskId ? { ...task, isToday: updatedTask.isToday } : task
      ));

    } catch (err) {
      setError('Failed to toggle today task. Please try again.');
    }
  }, [setTodayTasks, setOrderedTodayTasks, setProjects, setInboxTasks, setError]);

  const clearTodayTasks = useCallback(async () => {
    try {
      await api.clearTodayTasks();
      await fetchTodayTasks(); // Refresh the today tasks after clearing
      await fetchProjects(); // Refresh projects to update task statuses
    } catch (err) {
      setError('Failed to clear today\'s tasks. Please try again.');
    }
  }, [fetchTodayTasks, fetchProjects, setError]);

  return { 
    inboxTasks, 
    projects,
    todayTasks,
    orderedTodayTasks,
    addTask, 
    toggleTaskComplete, 
    deleteTask, 
    updateTask,
    updateTodayTasksOrder,
    toggleTodayTask,
    clearTodayTasks
  };
};