import React, { useState, useEffect, useCallback } from 'react';
import { DragDropContext } from '@hello-pangea/dnd';
import { GoogleOAuthProvider } from '@react-oauth/google';
import MainContent from './components/MainContent';
import LoadingIndicator from './components/LoadingIndicator';
import ErrorMessage from './components/ErrorMessage';
import { useAppContext } from './context/AppContext';
import { useDragAndDrop } from './hooks/useDragAndDrop';
import GoogleLoginButton from './components/GoogleLoginButton';
import { api, getCurrentUser } from './services/api';

import './index.css';

function App() {
  const { error, setUser, setError, setLoading } = useAppContext();
  const { onDragEnd } = useDragAndDrop();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);

  const checkAuth = useCallback(async () => {
    console.log('Checking authentication...');
    try {
      const user = await getCurrentUser();
      console.log('Auth check result:', user);
      if (user) {
        setUser(user);
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } catch (error) {
      console.error('Error checking authentication:', error);
      setIsAuthenticated(false);
    } finally {
      setIsCheckingAuth(false);
      setLoading(false);
    }
  }, [setUser, setLoading]);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  const handleLoginSuccess = async (response) => {
    console.log('Google login response:', response);
    try {
      const result = await api.post('/auth/google', {
        code: response.code
      });
      console.log('Backend login response:', result);
      localStorage.setItem('jwtToken', result.data.token); // Assuming the token is in result.data.token
      setUser(result.data.user);
      setIsAuthenticated(true);
    } catch (error) {
      console.error('Error logging in:', error);
      setError(`Failed to log in: ${error.response?.data?.error || error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleLoginFailure = (error) => {
    console.error('Login failed:', error);
    setError('Login failed');
  };

  const handleLogout = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/logout`, {
      method: 'GET',
      credentials: 'include',
    })
      .then(() => {
        setUser(null);
        setIsAuthenticated(false);
      })
      .catch(error => {
        console.error('Error logging out:', error);
        setError('Failed to log out');
      });
  };

  if (isCheckingAuth) return <LoadingIndicator />;
  if (error) return <ErrorMessage message={error} />;

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <div className="dark h-screen bg-dark-bg text-dark-text">
        {!isAuthenticated ? (
          <div className="flex items-center justify-center h-screen bg-dark-bg">
            <GoogleLoginButton onSuccess={handleLoginSuccess} onFailure={handleLoginFailure} />
          </div>
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <MainContent />
            <button 
              onClick={handleLogout}
              className="absolute top-4 right-4 bg-dark-primary text-dark-bg px-4 py-2 rounded hover:bg-opacity-80 transition-colors"
            >
              Logout
            </button>
          </DragDropContext>
        )}
      </div>
    </GoogleOAuthProvider>
  );
}

export default App;