import React, { useState } from 'react';
import { format, parseISO } from 'date-fns';
import { X, Edit } from 'lucide-react';
import { useAppContext } from '../../context/AppContext';
import EditTaskForm from '../forms/EditTaskForm';

function TaskDetails({ task, onClose, onUpdate}) {
  const { projects } = useAppContext();
  const [isEditing, setIsEditing] = useState(false);

  // Format date for display
  const formatDate = (dateString, isDateOnly = false) => {
    if (!dateString) return 'N/A';
    try {
      const date = parseISO(dateString);
      return isDateOnly 
        ? format(date, "MMM dd, yyyy")
        : format(date, "MMM dd, yyyy HH:mm");
    } catch (error) {
      console.error('Error parsing date:', error);
      return 'Invalid Date';
    }
  };

  // Get project name for the task
  const getProjectName = () => {
    if (!task.projectId) return 'Inbox';
    const project = projects.find(p => p._id === task.projectId);
    return project ? project.name : 'Unknown Project';
  };

  // Handle save after editing
  const handleSave = (updatedTask) => {
    onUpdate(task._id, updatedTask);
    setIsEditing(false);
  };

  return (
    <div className="bg-dark-surface border border-dark-muted rounded-lg p-6 mt-4">
      {/* Task header */}
      <div className="flex justify-between items-start mb-4">
        <div className="flex-grow pr-4">
          <h2 className="text-xl font-semibold text-dark-text">
            {task.name} 
            <span className="text-lg text-dark-muted ml-2">({getProjectName()})</span>
          </h2>
        </div>
        <div className="flex space-x-2">
          {!isEditing && (
            <button 
              onClick={() => setIsEditing(true)}
              className="text-dark-muted hover:text-dark-text transition-colors flex-shrink-0"
              aria-label="Edit task"
            >
              <Edit size={24} />
            </button>
          )}
          <button 
            onClick={onClose}
            className="text-dark-muted hover:text-dark-text transition-colors flex-shrink-0"
            aria-label="Close task details"
          >
            <X size={24} />
          </button>
        </div>
      </div>
      
      {/* Task content */}
      {isEditing ? (
        <EditTaskForm 
          task={task} 
          onSave={handleSave} 
          onCancel={() => setIsEditing(false)} 
        />
      ) : (
        <div className="space-y-4">
          {task.description && (
            <p className="text-base text-dark-text font-medium mb-4">{task.description}</p>
          )}

          <div className="grid grid-cols-2 gap-4">
            <div>
              <h3 className="text-sm text-dark-muted mb-1">Due Date</h3>
              <p className="text-base text-dark-text">{task.dueDate ? formatDate(task.dueDate, true) : 'Not set'}</p>
            </div>
            <div>
              <h3 className="text-sm text-dark-muted mb-1">Created On</h3>
              <p className="text-base text-dark-text">{formatDate(task.createdAt)}</p>
            </div>
            <div>
              <h3 className="text-sm text-dark-muted mb-1">Last Updated</h3>
              <p className="text-base text-dark-text">{formatDate(task.updatedAt)}</p>
            </div>
            <div>
              <h3 className="text-sm text-dark-muted mb-1">Completed On</h3>
              <p className="text-base text-dark-text">
                {task.completed ? formatDate(task.completedDate) : 'Incomplete'}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TaskDetails;