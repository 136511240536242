import React, { useState } from 'react';
import { useProjects } from '../../hooks/useProjects';

function AddProjectForm() {
  const [newProject, setNewProject] = useState('');
  const [newOutcome, setNewOutcome] = useState('');
  const { addProject } = useProjects();

  // Handle form submission
  const handleAddProject = () => {
    if (newProject.trim()) {
      addProject(newProject, newOutcome);
      setNewProject('');
      setNewOutcome('');
    }
  };

  // Handle keyboard shortcuts
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleAddProject();
    }
  };

  return (
    <div className="flex flex-col space-y-2">
      {/* Project Name Input */}
      <input
        type="text"
        value={newProject}
        onChange={(e) => setNewProject(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="New project name"
        className="w-full p-2 bg-dark-bg border border-dark-muted rounded-md text-sm text-dark-text focus:outline-none focus:ring-2 focus:ring-dark-primary"
      />
      {/* Project Outcome Input */}
      <textarea
        value={newOutcome}
        onChange={(e) => setNewOutcome(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Project outcome"
        className="w-full p-2 bg-dark-bg border border-dark-muted rounded-md text-sm text-dark-text focus:outline-none focus:ring-2 focus:ring-dark-primary resize-y min-h-[100px]"
      />
      {/* Add Project Button */}
      <button 
        onClick={handleAddProject}
        className="w-full bg-dark-primary text-dark-bg font-medium py-2 rounded-md hover:bg-opacity-80 transition-colors focus:outline-none focus:ring-2 focus:ring-dark-primary focus:ring-offset-2 focus:ring-offset-dark-bg"
      >
        Add Project
      </button>
    </div>
  );
}

export default AddProjectForm;