import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';

const GoogleLoginButton = ({ onSuccess, onFailure }) => {
  const login = useGoogleLogin({
    onSuccess: tokenResponse => onSuccess(tokenResponse),
    onError: error => onFailure(error),
    flow: 'auth-code',
    scope: 'openid profile email',
    redirect_uri: process.env.REACT_APP_CLIENT_URL
  });

  return (
    <button 
      onClick={() => login()}
      className="bg-white text-gray-700 font-semibold py-2 px-4 border border-gray-300 rounded shadow hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
    >
      <span className="flex items-center">
        <svg className="w-5 h-5 mr-2" viewBox="0 0 24 24">
          {/* ... SVG path data ... */}
        </svg>
        Sign in with Google
      </span>
    </button>
  );
};

export default GoogleLoginButton;