import { useCallback } from 'react';
import { useAppContext } from '../context/AppContext';
import * as api from '../services/api';

export const useProjects = () => {
  const { 
    projects, 
    setProjects,
    setTodayTasks,
    setError
  } = useAppContext();

  const addProject = useCallback(async (projectName, outcome) => {
    try {
      const newProject = await api.addProject(projectName, outcome);
      setProjects(prevProjects => [...prevProjects, newProject]);
    } catch (err) {
      setError('Failed to add project. Please try again.');
    }
  }, [setProjects, setError]);

  const updateProject = useCallback(async (projectId, updates) => {
    try {
      const updatedProject = await api.updateProject(projectId, updates);
      setProjects(prevProjects => 
        prevProjects.map(project => 
          project._id === projectId ? { ...project, ...updatedProject } : project
        )
      );
    } catch (err) {
      setError('Failed to update project. Please try again.');
    }
  }, [setProjects, setError]);

  const deleteProject = useCallback(async (projectId) => {
    try {
      await api.deleteProject(projectId);
      setProjects(prevProjects => prevProjects.filter(project => project._id !== projectId));
    } catch (err) {
      setError('Failed to delete project. Please try again.');
    }
  }, [setProjects, setError]);

  const toggleProjectStatus = useCallback(async (projectId) => {
    try {
      setProjects(prevProjects => 
        prevProjects.map(project => 
          project._id === projectId 
            ? { ...project, active: !project.active } 
            : project
        )
      );
      
      await api.toggleProjectStatus(projectId);
    } catch (err) {
      setError('Failed to toggle project status. Please try again.');
      // Revert the optimistic update
      setProjects(prevProjects => 
        prevProjects.map(project => 
          project._id === projectId 
            ? { ...project, active: !project.active } 
            : project
        )
      );
    }
  }, [setProjects, setError]);

  const toggleProjectComplete = useCallback(async (projectId) => {
    try {
      const updatedProjects = projects.map(project => 
        project._id === projectId 
          ? { ...project, completed: !project.completed } 
          : project
      );
      setProjects(updatedProjects);
      
      const completedProject = updatedProjects.find(p => p._id === projectId);
      if (completedProject && completedProject.completed) {
        // Remove project's tasks from Today's panel and update isToday status
        setTodayTasks(prevTasks => prevTasks.filter(task => task.projectId !== projectId));
        
        // Update isToday status for tasks in the project
        setProjects(prevProjects => 
          prevProjects.map(project => 
            project._id === projectId
              ? {
                  ...project,
                  tasks: project.tasks.map(task => ({ ...task, isToday: false }))
                }
              : project
          )
        );
      }
      
      const response = await api.toggleProjectComplete(projectId);
      return response; // Return the updated project
    } catch (err) {
      setError('Failed to toggle project completion status. Please try again.');
      // Revert the optimistic updates
      setProjects(prevProjects => 
        prevProjects.map(project => 
          project._id === projectId 
            ? { ...project, completed: !project.completed } 
            : project
        )
      );
    }
  }, [projects, setProjects, setTodayTasks, setError]);

  return { 
    projects, 
    addProject, 
    updateProject, 
    deleteProject, 
    toggleProjectStatus,
    toggleProjectComplete
  };
};