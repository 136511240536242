import React from 'react';
import { Droppable, Draggable } from '@hello-pangea/dnd';
import TaskItem from '../tasks/TaskItem';
import { useAppContext } from '../../context/AppContext';
import { useTasks } from '../../hooks/useTasks';

function TodayPanel({ onTaskSelect, onTaskDelete, showEmptyItem }) {
  const { orderedTodayTasks, projects, inboxTasks } = useAppContext();
  const { toggleTaskComplete, toggleTodayTask } = useTasks();

  const renderEmptyItem = () => (
    <div className="bg-dark-bg p-3 rounded cursor-pointer hover:bg-dark-muted transition-colors h-12"></div>
  );

  const getTaskById = (taskId) => {
    return inboxTasks.find(t => t._id === taskId) ||
           projects.flatMap(p => p.tasks).find(t => t._id === taskId);
  };

  const getProjectName = (task) => {
    if (!task.projectId) return 'Inbox';
    const project = projects.find(p => p._id === task.projectId);
    return project ? project.name : 'Unknown Project';
  };

  return (
    <Droppable droppableId="today">
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className={`space-y-2 min-h-[100px] ${snapshot.isDraggingOver ? 'bg-dark-primary bg-opacity-20' : ''}`}
        >
          {orderedTodayTasks.length > 0 ? (
            orderedTodayTasks.map((taskId, index) => {
              const task = getTaskById(taskId);
              if (!task) return null;
              return (
                <Draggable key={taskId} draggableId={`today-${taskId}`} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <TaskItem 
                        task={{
                          ...task,
                          name: `${task.name} (${getProjectName(task)})`
                        }} 
                        onSelect={() => onTaskSelect(task)} 
                        isDragging={snapshot.isDragging} 
                        onToggleComplete={toggleTaskComplete}
                        onDelete={() => onTaskDelete(task._id)}
                        onToggleToday={() => toggleTodayTask(task._id)}
                      />
                    </div>
                  )}
                </Draggable>
              );
            })
          ) : showEmptyItem ? (
            renderEmptyItem()
          ) : null}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

export default TodayPanel;