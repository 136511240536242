import React from 'react';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from './alert-dialog';

const DeleteProjectModal = ({ isOpen, onClose, onDelete }) => {
  return (
    <AlertDialog open={isOpen} onOpenChange={onClose}>
      <AlertDialogContent className="bg-dark-surface border border-dark-muted">
        <AlertDialogHeader>
          <AlertDialogTitle className="text-lg font-semibold text-dark-text">Delete Project</AlertDialogTitle>
          <AlertDialogDescription className="text-dark-muted">
            This action cannot be undone and will delete all tasks associated with this project. If you'd like to keep the project for future reference but remove it from active projects you can deactivate or complete the project instead. Are you sure you want to delete this project? 
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel className="bg-dark-bg text-dark-text border border-dark-muted hover:bg-dark-muted transition-colors">
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction 
            onClick={onDelete}
            className="bg-red-600 text-white hover:bg-red-700 transition-colors"
          >
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DeleteProjectModal;