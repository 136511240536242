import React, { useState } from 'react';
import { useTasks } from '../../hooks/useTasks';
import { Calendar } from 'lucide-react';

function AddTaskForm() {
  const [newTaskName, setNewTaskName] = useState('');
  const [newTaskDescription, setNewTaskDescription] = useState('');
  const [newTaskDueDate, setNewTaskDueDate] = useState('');
  const { addTask } = useTasks();

  // Handle form submission
  const handleAddTask = () => {
    if (newTaskName.trim()) {
      addTask(newTaskName, newTaskDescription, newTaskDueDate || null);
      setNewTaskName('');
      setNewTaskDescription('');
      setNewTaskDueDate('');
    }
  };

  // Handle keyboard shortcuts
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleAddTask();
    }
  };

  // Open the date picker when calendar icon is clicked
  const handleCalendarClick = () => {
    document.getElementById('taskDueDateInput').showPicker();
  };

  return (
    <div className="flex flex-col space-y-2">
      {/* Task Name Input */}
      <input
        type="text"
        value={newTaskName}
        onChange={(e) => setNewTaskName(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="New task name"
        className="w-full p-2 bg-dark-bg border border-dark-muted rounded-md text-sm text-dark-text focus:outline-none focus:ring-2 focus:ring-dark-primary"
      />
      {/* Task Description Input */}
      <textarea
        value={newTaskDescription}
        onChange={(e) => setNewTaskDescription(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Task description (optional)"
        className="w-full p-2 bg-dark-bg border border-dark-muted rounded-md text-sm text-dark-text focus:outline-none focus:ring-2 focus:ring-dark-primary resize-y min-h-[100px]"
      />
      {/* Task Due Date Input */}
      <div className="relative">
        <input
          id="taskDueDateInput"
          type="date"
          value={newTaskDueDate}
          onChange={(e) => setNewTaskDueDate(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Due date - mm/dd/yyyy (optional)"
          className="date-input w-full p-2 pr-10 bg-dark-bg border border-dark-muted rounded-md text-sm text-dark-text focus:outline-none focus:ring-2 focus:ring-dark-primary appearance-none [&::-webkit-calendar-picker-indicator]:opacity-0 [&::-webkit-calendar-picker-indicator]:absolute [&::-webkit-calendar-picker-indicator]:top-0 [&::-webkit-calendar-picker-indicator]:right-0 [&::-webkit-calendar-picker-indicator]:bottom-0 [&::-webkit-calendar-picker-indicator]:left-[calc(100%-2.5rem)] placeholder-dark-muted invalid:text-transparent"
        />
        <button 
          type="button" 
          onClick={handleCalendarClick}
          className="absolute right-2 top-1/2 transform -translate-y-1/2 text-dark-muted hover:text-dark-text focus:outline-none z-10"
        >
          <Calendar size={18} />
        </button>
      </div>
      {/* Add Task Button */}
      <button 
        onClick={handleAddTask}
        className="w-full bg-dark-primary text-dark-bg font-medium py-2 rounded-md hover:bg-opacity-80 transition-colors focus:outline-none focus:ring-2 focus:ring-dark-primary focus:ring-offset-2 focus:ring-offset-dark-bg"
      >
        Add Task
      </button>
    </div>
  );
}

export default AddTaskForm;