import React, { useState } from 'react';
import { useProjects } from '../../hooks/useProjects';

function EditProjectForm({ project, onSave, onCancel }) {
  const [name, setName] = useState(project.name);
  const [outcome, setOutcome] = useState(project.outcome || '');
  const { updateProject } = useProjects();

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    await updateProject(project._id, { name, outcome });
    onSave();
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      {/* Project Name Input */}
      <div>
        <label htmlFor="name" className="block text-sm font-medium text-dark-text mb-1">
          Project Name
        </label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full p-2 bg-dark-bg border border-dark-muted rounded-md text-sm text-dark-text focus:outline-none focus:ring-2 focus:ring-dark-primary"
          required
        />
      </div>

      {/* Project Outcome Input */}
      <div>
        <label htmlFor="outcome" className="block text-sm font-medium text-dark-text mb-1">
          Project Outcome
        </label>
        <textarea
          id="outcome"
          value={outcome}
          onChange={(e) => setOutcome(e.target.value)}
          className="w-full p-2 bg-dark-bg border border-dark-muted rounded-md text-sm text-dark-text focus:outline-none focus:ring-2 focus:ring-dark-primary resize-y min-h-[100px]"
        />
      </div>

      {/* Form Action Buttons */}
      <div className="flex justify-end space-x-2">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 bg-dark-bg text-dark-text border border-dark-muted rounded-md hover:bg-dark-muted transition-colors focus:outline-none focus:ring-2 focus:ring-dark-primary"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 bg-dark-primary text-dark-bg rounded-md hover:bg-opacity-80 transition-colors focus:outline-none focus:ring-2 focus:ring-dark-primary focus:ring-offset-2 focus:ring-offset-dark-bg"
        >
          Save Changes
        </button>
      </div>
    </form>
  );
}

export default EditProjectForm;