import React, { createContext, useContext, useState, useEffect, useCallback, useMemo } from 'react';
import * as api from '../services/api';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [inboxTasks, setInboxTasks] = useState([]);
  const [projects, setProjects] = useState([]);
  const [todayTasks, setTodayTasks] = useState([]);
  const [orderedTodayTasks, setOrderedTodayTasks] = useState([]); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);
  const [user, setUser] = useState(null);

  const fetchInboxTasks = useCallback(async () => {
    if (!user) return;
    try {
      const tasks = await api.fetchInboxTasks();
      setInboxTasks(tasks);
    } catch (err) {
      setError('Failed to fetch inbox tasks');
    }
  }, [user]);

  const fetchProjects = useCallback(async () => {
    if (!user) return;
    try {
      const fetchedProjects = await api.fetchProjects();
      setProjects(fetchedProjects);
    } catch (err) {
      setError('Failed to fetch projects');
    }
  }, [user]);

  const fetchTodayTasks = useCallback(async () => {
    if (!user) return;
    try {
      const tasks = await api.fetchTodayTasks();
      setTodayTasks(tasks);
      setOrderedTodayTasks(tasks.map(task => task._id));
    } catch (err) {
      setError('Failed to fetch today tasks');
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const fetchData = async () => {
        setLoading(true);
        try {
          await Promise.all([fetchInboxTasks(), fetchProjects(), fetchTodayTasks()]);
          setDataFetched(true);
        } catch (err) {
          setError('Failed to fetch data');
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    } else {
      // Reset state when user is not authenticated
      setInboxTasks([]);
      setProjects([]);
      setTodayTasks([]);
      setOrderedTodayTasks([]);
      setDataFetched(false);
      setLoading(false);
    }
  }, [user, fetchInboxTasks, fetchProjects, fetchTodayTasks]);

  const toggleProjectStatus = useCallback(async (projectId) => {
    if (!user) return;
    try {
      setLoading(true);
      const project = projects.find(p => p._id === projectId);
      await api.toggleProjectStatus(projectId, !project.active);
      await fetchProjects();
    } catch (err) {
      setError('Failed to toggle project status');
    } finally {
      setLoading(false);
    }
  }, [user, projects, fetchProjects]);

  const value = useMemo(() => ({
    inboxTasks,
    setInboxTasks,
    projects,
    setProjects,
    todayTasks,
    setTodayTasks,
    orderedTodayTasks,
    setOrderedTodayTasks,
    loading,
    setLoading,
    error,
    setError,
    dataFetched,
    fetchInboxTasks,
    fetchProjects,
    fetchTodayTasks,
    toggleProjectStatus,
    user,
    setUser
  }), [
    inboxTasks, projects, todayTasks, orderedTodayTasks, loading, error, dataFetched,
    fetchInboxTasks, fetchProjects, fetchTodayTasks, toggleProjectStatus, user
  ]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};