import React from 'react';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from './alert-dialog';

function DeleteTaskModal({ isOpen, onClose, onDelete, taskName }) {
  return (
    <AlertDialog open={isOpen} onOpenChange={onClose}>
      <AlertDialogContent className="bg-dark-surface border border-dark-muted">
        <AlertDialogHeader>
          <AlertDialogTitle className="text-lg font-semibold text-dark-text">Delete Task</AlertDialogTitle>
          <AlertDialogDescription className="text-dark-muted">
            Are you sure you want to delete the task "{taskName}"? This action cannot be undone.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel className="bg-dark-bg text-dark-text border border-dark-muted hover:bg-dark-muted transition-colors">
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction
            onClick={onDelete}
            className="bg-red-600 text-white hover:bg-red-700 transition-colors"
          >
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export default DeleteTaskModal;