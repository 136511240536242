import React from 'react';
import { useAppContext } from '../../context/AppContext';
import { useTasks } from '../../hooks/useTasks';
import LoadingIndicator from '../LoadingIndicator';
import ErrorMessage from '../ErrorMessage';
import TaskItem from '../tasks/TaskItem';

function NextActionsPanel({ onTaskSelect, onTaskDelete, onToggleToday, showEmptyItem }) {
  const { projects, loading, error } = useAppContext();
  const { toggleTaskComplete } = useTasks();

  if (loading) return <LoadingIndicator />;
  if (error) return <ErrorMessage />;

  const activeProjects = projects.filter(project => project.active && project.tasks.some(task => !task.completed));

  const renderEmptyItem = () => (
    <div className="bg-dark-bg p-3 rounded cursor-pointer hover:bg-dark-muted transition-colors h-12"></div>
  );

  return (
    <div className="space-y-2">
      {activeProjects.length > 0 ? (
        activeProjects.map(project => {
          const nextTask = project.tasks.find(task => !task.completed);
          if (!nextTask) return null;
          return (
            <TaskItem
              key={nextTask._id}
              task={{
                ...nextTask,
                name: `${nextTask.name} (${project.name})`
              }}
              onSelect={() => onTaskSelect(nextTask)}
              onToggleComplete={toggleTaskComplete}
              onDelete={() => onTaskDelete(nextTask._id)}
              onToggleToday={onToggleToday}
            />
          );
        })
      ) : showEmptyItem ? (
        renderEmptyItem()
      ) : null}
    </div>
  );
}

export default NextActionsPanel;