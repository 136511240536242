import React from 'react';
import { AlertDialog, AlertDialogAction, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from './alert-dialog';

/**
 * WelcomeModal component
 * Introduces new users to the Getting Things Done (GTD) methodology and app functionality:
 * 1. Capture: Explains how to add new projects and tasks
 * 2. Clarify: Encourages processing the Inbox regularly
 * 3. Organize: Describes how to move tasks to appropriate projects
 * 4. Reflect: Emphasizes the importance of regular review
 * 5. Engage: Highlights the Next Actions feature for focusing on immediate tasks
 */
const WelcomeModal = ({ isOpen, onClose }) => {
  return (
    <AlertDialog open={isOpen}>
      <AlertDialogContent className="bg-dark-surface border border-dark-muted">
        <AlertDialogHeader>
          <AlertDialogTitle className="text-2xl font-semibold text-dark-text">Welcome to Your GTD App</AlertDialogTitle>
          <AlertDialogDescription className="text-dark-muted">
            To get started with the Getting Things Done (GTD) method:
            <ol className="list-decimal list-inside mt-2 space-y-1">
              <li>Create projects for your outcomes using the "Add Project" form in the sidebar.</li>
              <li>Capture tasks and ideas into your Inbox using the "Add Task" form.</li>
              <li>Process your Inbox regularly: clarify what each item means and what action it requires.</li>
              <li>Organize by moving tasks to appropriate projects or lists.</li>
            </ol>
            Remember, the key to GTD is to capture everything, clarify what needs to be done, organize it, and regularly review your system.
            
            <p className="mt-2">The Next Actions panel automatically displays the top task from each active project, helping you focus on your immediate next steps.</p>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogAction 
            onClick={onClose}
            className="bg-dark-primary text-dark-bg hover:bg-dark-primary hover:bg-opacity-80 focus:ring-dark-primary"
          >
            Got it, let's start!
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default WelcomeModal;