import React, { useState, useEffect, useMemo } from 'react';
import FocusPanel from './FocusPanel';
import Inbox from './Inbox';
import ProjectsContainer from './projects/ProjectsContainer';
import TaskDetails from './tasks/TaskDetails';
import EditTaskForm from './forms/EditTaskForm';
import WelcomeModal from './modals/WelcomeModal';
import Sidebar from './Sidebar';
import ImportExportModal from './modals/ImportExportModal';
import { useAppContext } from '../context/AppContext';
import { useTasks } from '../hooks/useTasks';
import { Menu, Briefcase, CheckSquare, Calendar } from 'lucide-react';
import { calculateStats } from '../utils/statsUtils';
import * as api from '../services/api';

function MainContent() {
  const { inboxTasks, projects, loading, dataFetched, fetchInboxTasks, fetchProjects, fetchTodayTasks } = useAppContext();
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const { updateTask, deleteTask, toggleTodayTask } = useTasks();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showImportExportModal, setShowImportExportModal] = useState(false);
  const [importExportMode, setImportExportMode] = useState(null);

  const stats = useMemo(() => calculateStats(projects, inboxTasks), [projects, inboxTasks]);

  useEffect(() => {
    if (dataFetched && projects.length === 0) {
      setShowWelcomeModal(true);
    } else {
      setShowWelcomeModal(false);
    }
  }, [dataFetched, projects]);

  const handleImportExport = (mode) => {
    setImportExportMode(mode);
    setShowImportExportModal(true);
  };

  const refreshData = async () => {
    await Promise.all([fetchInboxTasks(), fetchProjects(), fetchTodayTasks()]);
  };

  const handleExport = async () => {
    try {
      const response = await api.exportData();
      const blob = new Blob([JSON.stringify(response, null, 2)], { type: 'application/json' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'gtd_app_export.json';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Export failed:', error);
      throw error;
    }
  };

  const handleImport = async (file) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      await api.importData(formData);
      await refreshData();
    } catch (error) {
      console.error('Import failed:', error);
      throw error;
    }
  };

  const handleTaskDelete = async (taskId) => {
    const isDeleted = await deleteTask(taskId);
    if (isDeleted) {
      if (selectedTask && selectedTask._id === taskId) {
        setSelectedTask(null);
        setIsEditing(false);
      }
      await refreshData();
    }
  };

  const handleTaskSelect = (task) => {
    setSelectedTask(task);
    setIsEditing(false);
  };

  const handleTaskEdit = (task) => {
    setSelectedTask(task);
    setIsEditing(true);
  };

  const handleTaskUpdate = async (taskId, updates) => {
    const updatedTask = await updateTask(taskId, updates);
    setSelectedTask(updatedTask);
    setIsEditing(false);
  };

  const handleTaskClose = () => {
    setSelectedTask(null);
    setIsEditing(false);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex h-screen bg-dark-bg">
      <Sidebar 
        isOpen={sidebarOpen} 
        onClose={() => setSidebarOpen(false)} 
        onImportExport={handleImportExport}
      />
      <div className="flex-1 flex flex-col overflow-hidden">
        <header className="bg-dark-surface p-4 flex items-center justify-between lg:hidden">
          <button
            onClick={toggleSidebar}
            className="text-dark-muted hover:text-dark-text transition-colors"
            aria-label="Toggle sidebar"
          >
            <Menu size={24} />
          </button>
          <h1 className="text-xl font-bold text-dark-text absolute left-1/2 transform -translate-x-1/2">
            GTD App
          </h1>
          <div className="flex flex-col justify-center text-[10px] leading-tight">
            <div className="flex items-center">
              <span className="text-dark-muted font-medium w-16">Active:</span>
              <div className="flex items-center space-x-1">
                <Briefcase size={12} className="text-dark-primary" />
                <span className="text-dark-text font-semibold">{stats.activeProjects}</span>
                <span className="text-dark-muted font-medium">Projects</span>
              </div>
              <div className="flex items-center space-x-1 ml-2">
                <CheckSquare size={12} className="text-dark-primary" />
                <span className="text-dark-text font-semibold">{stats.activeTasks}</span>
                <span className="text-dark-muted font-medium">Tasks</span>
              </div>
            </div>
            <div className="flex items-center mt-1">
              <span className="text-dark-muted font-medium w-16">Completed:</span>
              <div className="flex items-center space-x-1">
                <Calendar size={12} className="text-dark-primary" />
                <span className="text-dark-text font-semibold">{stats.completedToday}</span>
                <span className="text-dark-muted font-medium">Today</span>
              </div>
              <div className="flex items-center space-x-1 ml-2">
                <Calendar size={12} className="text-dark-primary" />
                <span className="text-dark-text font-semibold">{stats.completedThisWeek}</span>
                <span className="text-dark-muted font-medium">Week</span>
              </div>
            </div>
          </div>
        </header>
        <main className="flex-1 overflow-x-hidden overflow-y-auto">
          <div className="p-6 space-y-6">
            <FocusPanel onTaskSelect={handleTaskSelect} onTaskDelete={handleTaskDelete} />
            {inboxTasks.length > 0 && <Inbox onTaskEdit={handleTaskEdit} onTaskDelete={handleTaskDelete} />}
            {selectedTask && (
              isEditing ? (
                <EditTaskForm 
                  task={selectedTask} 
                  onSave={(updates) => handleTaskUpdate(selectedTask._id, updates)}
                  onCancel={handleTaskClose}
                  onDelete={() => handleTaskDelete(selectedTask._id)}
                />
              ) : (
                <TaskDetails 
                  task={selectedTask} 
                  onClose={handleTaskClose}
                  onEdit={() => setIsEditing(true)}
                  onUpdate={handleTaskUpdate}
                  onDelete={() => handleTaskDelete(selectedTask._id)}
                />
              )
            )}
            <ProjectsContainer 
              onTaskSelect={handleTaskSelect} 
              onTaskDelete={handleTaskDelete}
              onToggleToday={toggleTodayTask} // Add this line
            />
          </div>
        </main>
      </div>
      <WelcomeModal isOpen={showWelcomeModal} onClose={() => setShowWelcomeModal(false)} />
      <ImportExportModal
        isOpen={showImportExportModal}
        onClose={() => {
          setShowImportExportModal(false);
          setImportExportMode(null);
        }}
        onExport={handleExport}
        onImport={handleImport}
        mode={importExportMode}
      />
    </div>
  );
}

export default MainContent;