import React, { useState, useEffect } from 'react';
import { useTasks } from '../../hooks/useTasks';
import { Calendar } from 'lucide-react';
import { parseISO, format, addDays } from 'date-fns';

function EditTaskForm({ task, onSave, onCancel }) {
  const [name, setName] = useState(task.name);
  const [description, setDescription] = useState(task.description || '');
  const [dueDate, setDueDate] = useState(task.dueDate ? format(parseISO(task.dueDate), 'yyyy-MM-dd') : '');
  const { updateTask } = useTasks();

  // Update local state when task prop changes
  useEffect(() => {
    setName(task.name);
    setDescription(task.description || '');
    setDueDate(task.dueDate ? format(parseISO(task.dueDate), 'yyyy-MM-dd') : '');
  }, [task]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    let adjustedDate = null;
    if (dueDate) {
      // Create a date object for the selected date at midnight in the local timezone
      const localDate = new Date(dueDate + 'T00:00:00');
      // Adjust for timezone offset
      const timezoneOffset = localDate.getTimezoneOffset();
      adjustedDate = addDays(localDate, timezoneOffset / 1440).toISOString();
    }

    await updateTask(task._id, { 
      name, 
      description, 
      dueDate: adjustedDate
    });
    onSave();
  };

  const handleCalendarClick = () => {
    document.getElementById('editTaskDueDateInput').showPicker();
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      {/* Task Name Input */}
      <div>
        <label htmlFor="name" className="block text-sm font-medium text-dark-text mb-1">
          Task Name
        </label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full p-2 bg-dark-bg border border-dark-muted rounded-md text-sm text-dark-text focus:outline-none focus:ring-2 focus:ring-dark-primary"
          required
        />
      </div>

      {/* Task Description Input */}
      <div>
        <label htmlFor="description" className="block text-sm font-medium text-dark-text mb-1">
          Description
        </label>
        <textarea
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="w-full p-2 bg-dark-bg border border-dark-muted rounded-md text-sm text-dark-text focus:outline-none focus:ring-2 focus:ring-dark-primary resize-y min-h-[100px]"
        />
      </div>

      {/* Task Due Date Input */}
      <div>
        <label htmlFor="editTaskDueDateInput" className="block text-sm font-medium text-dark-text mb-1">
          Due Date
        </label>
        <div className="relative">
          <input
            id="editTaskDueDateInput"
            type="date"
            value={dueDate}
            onChange={(e) => setDueDate(e.target.value)}
            className="w-full p-2 pr-10 bg-dark-bg border border-dark-muted rounded-md text-sm text-dark-text focus:outline-none focus:ring-2 focus:ring-dark-primary appearance-none [&::-webkit-calendar-picker-indicator]:opacity-0 [&::-webkit-calendar-picker-indicator]:absolute [&::-webkit-calendar-picker-indicator]:top-0 [&::-webkit-calendar-picker-indicator]:right-0 [&::-webkit-calendar-picker-indicator]:bottom-0 [&::-webkit-calendar-picker-indicator]:left-[calc(100%-2.5rem)]"
          />
          <button 
            type="button" 
            onClick={handleCalendarClick}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-dark-muted hover:text-dark-text focus:outline-none"
          >
            <Calendar size={18} />
          </button>
        </div>
      </div>

      {/* Form Action Buttons */}
      <div className="flex justify-end space-x-2">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 bg-dark-bg text-dark-text border border-dark-muted rounded-md hover:bg-dark-muted transition-colors focus:outline-none focus:ring-2 focus:ring-dark-primary"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 bg-dark-primary text-dark-bg rounded-md hover:bg-opacity-80 transition-colors focus:outline-none focus:ring-2 focus:ring-dark-primary focus:ring-offset-2 focus:ring-offset-dark-bg"
        >
          Save Changes
        </button>
      </div>
    </form>
  );
}

export default EditTaskForm;