import React, { useState, useEffect } from 'react';
import { Droppable, Draggable } from '@hello-pangea/dnd';
import TaskItem from '../tasks/TaskItem';
import { useAppContext } from '../../context/AppContext';
import { useProjects } from '../../hooks/useProjects';
import { useTasks } from '../../hooks/useTasks';
import LoadingIndicator from '../LoadingIndicator';
import ErrorMessage from '../ErrorMessage';
import { ChevronDown, ChevronUp } from 'lucide-react';
import DeleteProjectModal from '../modals/DeleteProjectModal';
import ProjectDetails from './ProjectDetails';
import EditProjectForm from '../forms/EditProjectForm';

function Project({ project, onTaskSelect, onTaskDelete, onToggleToday }) {
  const { loading, error } = useAppContext();
  const { deleteProject, updateProject, toggleProjectStatus, toggleProjectComplete } = useProjects();
  const [showCompleted, setShowCompleted] = useState(project.completed);
  const [showDetails, setShowDetails] = useState(project.completed);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const { toggleTaskComplete } = useTasks();

  useEffect(() => {
    setShowCompleted(project.completed);
    setShowDetails(project.completed);
  }, [project.completed]);

  if (loading) return <LoadingIndicator />;
  if (error) return <ErrorMessage />;

  const activeTasks = project.tasks.filter(task => !task.completed);
  const completedTasks = project.tasks.filter(task => task.completed);

  const handleDeleteConfirm = () => {
    deleteProject(project._id);
    setShowDeleteModal(false);
  };

  const handleSave = async (updatedProject) => {
    await updateProject(project._id, updatedProject);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleToggleComplete = () => {
    toggleProjectComplete(project._id);
  };

  if (isEditing) {
    return (
      <EditProjectForm
        project={project}
        onSave={handleSave}
        onCancel={handleCancel}
      />
    );
  }

  return (
    <Droppable droppableId={project._id}>
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className={`w-full border border-dark-muted p-4 rounded-lg bg-dark-surface ${
            snapshot.isDraggingOver ? 'bg-dark-primary bg-opacity-20' : ''
          }`}
        >
          <div className="flex justify-between items-center mb-2">
            <h2 className="text-xl font-semibold text-dark-text flex-shrink-0">{project.name}</h2>
            <div className="flex items-center justify-center flex-grow mx-4">
              <button 
                onClick={() => setShowDetails(!showDetails)}
                className="flex items-center px-3 py-1 bg-dark-bg text-dark-text rounded hover:bg-dark-muted transition-colors border border-dark-muted"
              >
                {showDetails ? (
                  <>
                    Hide Details
                    <ChevronUp className="ml-1" size={16} />
                  </>
                ) : (
                  <>
                    Show Details
                    <ChevronDown className="ml-1" size={16} />
                  </>
                )}
              </button>
            </div>
          </div>
          
          {showDetails && (
            <ProjectDetails 
              project={project}
              onToggleStatus={() => toggleProjectStatus(project._id)}
              onToggleCompleted={() => setShowCompleted(!showCompleted)}
              showCompleted={showCompleted}
              onEdit={() => setIsEditing(true)}
              onDelete={() => setShowDeleteModal(true)}
              onToggleComplete={handleToggleComplete}
            />
          )}

          <div className="space-y-2 min-h-[50px] mt-4">
          {activeTasks.map((task, index) => (
            <Draggable key={task._id} draggableId={task._id} index={index}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <TaskItem 
                    task={task} 
                    onSelect={() => onTaskSelect(task)}
                    isDragging={snapshot.isDragging}
                    onToggleComplete={toggleTaskComplete}
                    onDelete={() => onTaskDelete(task._id)}
                    onToggleToday={onToggleToday} // Add this line
                  />
                </div>
              )}
            </Draggable>
          ))}
            {provided.placeholder}
          </div>

          {showCompleted && completedTasks.length > 0 && (
            <div className="mt-4 pt-4 border-t border-dark-muted">
              <h3 className="text-xl font-semibold text-dark-text mb-2">Completed Tasks</h3>
              <div className="space-y-2">
                {completedTasks.map((task) => (
                  <div key={task._id} onClick={() => onTaskSelect(task)}>
                    <TaskItem 
                      task={task} 
                      onSelect={() => onTaskSelect(task)}
                      onToggleComplete={toggleTaskComplete}
                      onDelete={() => onTaskDelete(task._id)}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}

          <DeleteProjectModal 
            isOpen={showDeleteModal} 
            onClose={() => setShowDeleteModal(false)} 
            onDelete={handleDeleteConfirm}
          />
        </div>
      )}
    </Droppable>
  );
}

export default Project;