import React from 'react';
import { useAppContext } from '../context/AppContext';

/**
 * LoadingIndicator component
 * Displays a spinning loading indicator when the application is in a loading state
 * @returns {React.Component|null} The LoadingIndicator component or null if not loading
 */
function LoadingIndicator() {
  const { loading } = useAppContext();

  if (!loading) return null;

  return (
    <div className="flex items-center justify-center h-screen bg-dark-bg">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-dark-primary"></div>
    </div>
  );
}

export default LoadingIndicator;