import { startOfDay, startOfWeek } from 'date-fns';

export const calculateStats = (projects, inboxTasks) => {
  const activeProjects = projects.filter(project => project.active).length;
  const activeTasks = projects.reduce((sum, project) => 
    sum + project.tasks.filter(task => !task.completed).length, 0
  ) + inboxTasks.filter(task => !task.completed).length;

  const now = new Date();
  const todayStart = startOfDay(now);
  const weekStart = startOfWeek(now, { weekStartsOn: 0 }); // 0 represents Sunday

  const completedToday = projects.reduce((sum, project) => 
    sum + project.tasks.filter(task => 
      task.completed && 
      new Date(task.completedDate) >= todayStart
    ).length, 0
  ) + inboxTasks.filter(task => 
    task.completed && 
    new Date(task.completedDate) >= todayStart
  ).length;

  const completedThisWeek = projects.reduce((sum, project) => 
    sum + project.tasks.filter(task => 
      task.completed && 
      new Date(task.completedDate) >= weekStart
    ).length, 0
  ) + inboxTasks.filter(task => 
    task.completed && 
    new Date(task.completedDate) >= weekStart
  ).length;

  return { activeProjects, activeTasks, completedToday, completedThisWeek };
};