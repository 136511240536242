import React from 'react';
import { Trash2, Edit } from 'lucide-react';

const ProjectDetails = ({ project, onToggleStatus, onToggleCompleted, showCompleted, onEdit, onDelete, onToggleComplete }) => {
  const activeTasks = project.tasks.filter(task => !task.completed);
  const completedTasks = project.tasks.filter(task => task.completed);
  const totalTasks = project.tasks.length;

  return (
    <div className="bg-dark-bg rounded-lg p-4 space-y-3">
      <div className="flex justify-between items-start">
        <div>
          <h3 className="text-lg font-semibold text-dark-text mb-1">Project Details</h3>
          <p className="text-sm text-dark-muted">{project.outcome}</p>
        </div>
        <div className="flex space-x-2">
          <button 
            onClick={onEdit}
            className="p-1 text-dark-muted hover:text-dark-text transition-colors"
            aria-label="Edit project"
          >
            <Edit size={18} />
          </button>
          <button 
            onClick={onDelete}
            className="p-1 text-dark-muted hover:text-red-500 transition-colors"
            aria-label="Delete project"
          >
            <Trash2 size={18} />
          </button>
        </div>
      </div>

      <div className="space-y-1 text-xs">
        <p className="text-dark-muted">Created: {new Date(project.createdAt).toLocaleString()}</p>
        <p className="text-dark-muted">Last Updated: {new Date(project.updatedAt).toLocaleString()}</p>
        <p className="text-dark-muted">
          Tasks: {activeTasks.length} active, {completedTasks.length} completed, {totalTasks} total
        </p>
      </div>

      <div className="flex flex-col space-y-2">
        <div className="flex items-center space-x-2">
          {!project.completed && (
            <button 
              onClick={onToggleStatus}
              className={`flex-1 px-3 py-1 font-medium rounded transition-colors ${
                project.active 
                  ? 'bg-dark-primary text-dark-bg hover:bg-opacity-80' 
                  : 'bg-dark-muted text-dark-bg hover:bg-opacity-80'
              }`}
            >
              {project.active ? 'Deactivate' : 'Activate'}
            </button>
          )}
          <button 
            onClick={onToggleComplete}
            className={`flex-1 px-3 py-1 font-medium rounded transition-colors ${
              project.completed
                ? 'bg-dark-muted text-dark-bg hover:bg-opacity-80'
                : 'bg-green-600 text-dark-bg hover:bg-opacity-80'
            }`}
          >
            {project.completed ? 'Reopen' : 'Complete'}
          </button>
        </div>
        {!project.completed && (
          <button 
            onClick={onToggleCompleted}
            className="w-full px-3 py-1 bg-dark-surface text-dark-text rounded hover:bg-dark-hover transition-colors border border-dark-muted"
          >
            {showCompleted ? 'Hide Completed' : 'Show Completed'}
          </button>
        )}
      </div>
    </div>
  );
};

export default ProjectDetails;