import axios from 'axios';

const API_BASE_URL = process.env.NODE_ENV === 'production' 
  ? 'https://gtd-app-tau.vercel.app/api'
  : (process.env.REACT_APP_API_BASE_URL || 'http://localhost:3110/api');

export const api = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const getCurrentUser = async () => {
  try {
    const response = await api.get('/auth/current_user');
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      return null;
    }
    throw error;
  }
};

export const fetchProjects = async () => {
  const response = await api.get('/projects');
  return response.data;
};

export const fetchInboxTasks = async () => {
  const response = await api.get('/inbox');
  return response.data;
};

export const fetchTodayTasks = async () => {
  const response = await api.get('/today');
  return response.data;
};

export const updateTodayTasksOrder = async (orderedTaskIds) => {
  const response = await api.put('/today/reorder', { orderedTaskIds });
  return response.data;
};

export const addProject = async (projectName, outcome) => {
  const response = await api.post('/projects', { name: projectName, outcome, active: true });
  return response.data;
};

export const updateProject = async (projectId, updates) => {
  const response = await api.put(`/projects/${projectId}`, updates);
  return response.data;
};

export const deleteProject = async (projectId) => {
  const response = await api.delete(`/projects/${projectId}`);
  return response.data;
};

export const addTask = async (name, description, dueDate) => {
  const response = await api.post('/inbox', { name, description, dueDate });
  return response.data;
};

export const updateTask = async (taskId, updates) => {
  const response = await api.put(`/tasks/${taskId}`, updates);
  return response.data;
};

export const deleteTask = async (taskId) => {
  const response = await api.delete(`/tasks/${taskId}`);
  return response.data;
};

export const toggleProjectStatus = async (projectId) => {
  const response = await api.put(`/projects/${projectId}/toggle-status`);
  return response.data;
};

export const reorderTasks = async (sourceId, destinationId, taskId, newIndex) => {
  const response = await api.put('/reorder-tasks', {
    sourceId,
    destinationId,
    taskId,
    newIndex,
  });
  return response.data;
};

export const toggleTodayTask = async (taskId) => {
  const response = await api.put(`/tasks/${taskId}/toggle-today`);
  return response.data;
};

export const clearTodayTasks = async () => {
  const response = await api.post('/clear-today-tasks');
  return response.data;
};

export const exportData = async () => {
  const response = await api.get('/export');
  return response.data;
};

export const importData = async (formData) => {
  const response = await api.post('/import', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const toggleProjectComplete = async (projectId) => {
  const response = await api.put(`/projects/${projectId}/toggle-complete`);
  return response.data;
};