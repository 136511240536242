import React, { useMemo } from 'react';
import LoadingIndicator from './LoadingIndicator';
import ErrorMessage from './ErrorMessage';
import { useAppContext } from '../context/AppContext';
import AddProjectForm from './forms/AddProjectForm';
import AddTaskForm from './forms/AddTaskForm';
import { User, Briefcase, CheckSquare, Calendar, X, Download, Upload } from 'lucide-react';
import { calculateStats } from '../utils/statsUtils';

function Sidebar({ isOpen, onClose, onImportExport }) {
  const { loading, error, projects, inboxTasks } = useAppContext();

  const stats = useMemo(() => calculateStats(projects, inboxTasks), [projects, inboxTasks]);

  if (loading) return <LoadingIndicator />;
  if (error) return <ErrorMessage />;

  return (
    <div className={`fixed inset-y-0 left-0 z-30 w-80 min-w-[300px] bg-dark-surface shadow-md overflow-y-auto transition-transform duration-300 ease-in-out transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} lg:relative lg:translate-x-0`}>
      <div className="p-6 space-y-8">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold text-dark-text">GTD App</h1>
          <button
            onClick={onClose}
            className="lg:hidden text-dark-muted hover:text-dark-text transition-colors"
            aria-label="Close sidebar"
          >
            <X size={24} />
          </button>
        </div>
        
        {/* User info and statistics - hidden on mobile, visible on larger screens */}
        <div className="hidden lg:block bg-dark-bg rounded-lg p-4 shadow-lg border border-dark-primary">
          <div className="flex items-center space-x-3 mb-4">
            <User className="text-dark-primary" size={24} />
            <h2 className="text-lg font-semibold text-dark-text">Jared Hicken</h2>
          </div>
          <div className="space-y-2">
            <StatItem icon={Briefcase} label="Active Projects" value={stats.activeProjects} />
            <StatItem icon={CheckSquare} label="Active Tasks" value={stats.activeTasks} />
            <StatItem icon={Calendar} label="Completed Today" value={stats.completedToday} />
            <StatItem icon={Calendar} label="Completed This Week" value={stats.completedThisWeek} />
          </div>
        </div>

        {/* Input forms - support Capture phase */}
        <div className="space-y-6">
          <section>
            <h2 className="text-lg font-semibold text-dark-text mb-4">Add Project</h2>
            <AddProjectForm />
          </section>
          <section>
            <h2 className="text-lg font-semibold text-dark-text mb-4">Add Task</h2>
            <AddTaskForm />
          </section>
        </div>
        <div className="flex items-center justify-between">
          <h2 className="text-lg font-semibold text-dark-text">Data Management</h2>
          <div className="flex space-x-2">
            <button
              onClick={() => onImportExport('export')}
              className="p-2 bg-dark-bg text-dark-text rounded-full hover:bg-dark-hover transition-colors"
              aria-label="Export data"
            >
              <Download size={20} />
            </button>
            <button
              onClick={() => onImportExport('import')}
              className="p-2 bg-dark-bg text-dark-text rounded-full hover:bg-dark-hover transition-colors"
              aria-label="Import data"
            >
              <Upload size={20} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function StatItem({ icon: Icon, label, value }) {
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center space-x-2">
        <Icon className="text-dark-muted" size={16} />
        <span className="text-sm text-dark-muted">{label}:</span>
      </div>
      <span className="text-sm font-semibold text-dark-primary">{value}</span>
    </div>
  );
}

export default Sidebar;