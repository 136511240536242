import React, { useMemo } from 'react';
import { useAppContext } from '../../context/AppContext';
import { parseISO, format } from 'date-fns';

function CompletedTasksPanel({ onTaskSelect, onTaskDelete }) {
  const { projects, inboxTasks } = useAppContext();

  const completedTasks = useMemo(() => {
    const allTasks = [
      ...inboxTasks,
      ...projects.flatMap(project => project.tasks.map(task => ({ ...task, projectName: project.name })))
    ];

    return allTasks
      .filter(task => task.completed)
      .sort((a, b) => parseISO(b.completedDate) - parseISO(a.completedDate));
  }, [projects, inboxTasks]);

  const getProjectName = (task) => {
    return task.projectName || 'Inbox';
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = parseISO(dateString);
    return format(date, "MMM dd, yyyy");
  };

  return (
    <div className="space-y-2">
      {completedTasks.length > 0 ? (
        completedTasks.map((task) => (
          <div 
            key={task._id}
            className="flex items-center bg-dark-bg p-3 rounded cursor-pointer hover:bg-dark-hover transition-colors"
            onClick={() => onTaskSelect(task)}
          >
            <div className="flex-shrink-0 mr-3 min-w-[80px] text-sm font-medium text-dark-primary">
              {formatDate(task.completedDate)}
            </div>
            <div className="flex-grow">
              <span className="text-base text-dark-text truncate pr-16">
                {task.name} ({getProjectName(task)})
              </span>
            </div>
          </div>
        ))
      ) : (
        <p className="text-center text-dark-muted">No completed tasks.</p>
      )}
    </div>
  );
}

export default CompletedTasksPanel;