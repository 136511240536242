import React from 'react';
import { Droppable, Draggable } from '@hello-pangea/dnd';
import { useAppContext } from '../context/AppContext';
import { useTasks } from '../hooks/useTasks';
import TaskItem from './tasks/TaskItem';

function Inbox({ onTaskEdit, onTaskDelete }) {
  const { inboxTasks } = useAppContext();
  const { toggleTaskComplete } = useTasks();

  if (inboxTasks.length === 0) {
    return null;
  }

  return (
    <div className="bg-dark-surface rounded-lg shadow-md p-6 w-full mb-6">
      <h2 className="text-xl font-semibold text-dark-text mb-4">Inbox</h2>
      <Droppable droppableId="inbox">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={`space-y-2 min-h-[100px] ${snapshot.isDraggingOver ? 'bg-dark-primary bg-opacity-20' : ''}`}
          >
            {inboxTasks.map((task, index) => (
              <Draggable key={task._id} draggableId={task._id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <TaskItem 
                      task={task} 
                      onSelect={() => onTaskEdit(task)} 
                      isDragging={snapshot.isDragging} 
                      onToggleComplete={() => toggleTaskComplete(task._id, !task.completed)}
                      onDelete={() => onTaskDelete(task._id)}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
}

export default Inbox;