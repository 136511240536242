import React, { useState } from 'react';
import NextActionsPanel from './panels/NextActionsPanel';
import TodayPanel from './panels/TodayPanel';
import ScheduledTasksPanel from './panels/ScheduledTasksPanel';
import CompletedTasksPanel from './panels/CompletedTasksPanel';
import { useTasks } from '../hooks/useTasks';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from './modals/alert-dialog';
import { addDays, isToday, parseISO } from 'date-fns';

const FocusPanel = ({ onTaskSelect, onTaskDelete }) => {
  const [activeTab, setActiveTab] = useState('nextActions');
  const [showClearConfirmation, setShowClearConfirmation] = useState(false);
  const { clearTodayTasks, toggleTodayTask, projects, inboxTasks } = useTasks();

  const handleClearTodayTasks = () => {
    setShowClearConfirmation(true);
  };

  const confirmClearTodayTasks = () => {
    clearTodayTasks();
    setShowClearConfirmation(false);
  };

  const handleAddScheduledTasks = () => {
    const today = new Date();
    const tomorrow = addDays(today, 1);

    const allTasks = [
      ...inboxTasks,
      ...projects.flatMap(project => project.tasks)
    ];

    allTasks.forEach(task => {
      if (task.dueDate) {
        const dueDate = parseISO(task.dueDate);
        if (isToday(dueDate) || (dueDate > today && dueDate < tomorrow)) {
          if (!task.isToday) {
            toggleTodayTask(task._id);
          }
        }
      }
    });
  };

  return (
    <div className="bg-dark-surface rounded-lg shadow-md p-6 w-full mb-6">
      <div className="flex mb-4 justify-between items-center">
        <div className="flex space-x-2">
          <button
            className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
              activeTab === 'nextActions'
                ? 'bg-dark-primary text-dark-bg'
                : 'bg-dark-bg text-dark-muted hover:text-dark-text'
            }`}
            onClick={() => setActiveTab('nextActions')}
          >
            Next Actions
          </button>
          <button
            className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
              activeTab === 'today'
                ? 'bg-dark-primary text-dark-bg'
                : 'bg-dark-bg text-dark-muted hover:text-dark-text'
            }`}
            onClick={() => setActiveTab('today')}
          >
            Today's Focus
          </button>
          <button
            className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
              activeTab === 'scheduledTasks'
                ? 'bg-dark-primary text-dark-bg'
                : 'bg-dark-bg text-dark-muted hover:text-dark-text'
            }`}
            onClick={() => setActiveTab('scheduledTasks')}
          >
            Scheduled Tasks
          </button>
          <button
            className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
              activeTab === 'completedTasks'
                ? 'bg-dark-primary text-dark-bg'
                : 'bg-dark-bg text-dark-muted hover:text-dark-text'
            }`}
            onClick={() => setActiveTab('completedTasks')}
          >
            Completed Tasks
          </button>
        </div>
        {activeTab === 'today' && (
          <div className="flex space-x-2">
            <button
              onClick={handleAddScheduledTasks}
              className="px-3 py-1 bg-dark-bg text-dark-text text-sm font-medium rounded hover:bg-dark-hover transition-colors"
            >
              Add Scheduled Tasks
            </button>
            <button
              onClick={handleClearTodayTasks}
              className="px-3 py-1 bg-dark-bg text-dark-text text-sm font-medium rounded hover:bg-dark-hover transition-colors"
            >
              Clear Today's Focus
            </button>
          </div>
        )}
      </div>
      <div className="mt-4">
        {activeTab === 'nextActions' && (
          <NextActionsPanel 
            onTaskSelect={onTaskSelect} 
            onTaskDelete={onTaskDelete} 
            onToggleToday={toggleTodayTask}
            showEmptyItem={true} 
          />
        )}
        {activeTab === 'scheduledTasks' && (
          <ScheduledTasksPanel 
            onTaskSelect={onTaskSelect} 
            onTaskDelete={onTaskDelete} 
            onToggleToday={toggleTodayTask}
            showEmptyItem={true} 
          />
        )}
        {activeTab === 'today' && (
          <TodayPanel 
            onTaskSelect={onTaskSelect} 
            onTaskDelete={onTaskDelete} 
            onToggleToday={toggleTodayTask}
            showEmptyItem={true} 
          />
        )}
        {activeTab === 'completedTasks' && (
          <CompletedTasksPanel 
            onTaskSelect={onTaskSelect} 
            onTaskDelete={onTaskDelete}
          />
        )}
      </div>
  
      <AlertDialog open={showClearConfirmation} onOpenChange={setShowClearConfirmation}>
        <AlertDialogContent className="bg-dark-surface border border-dark-muted">
          <AlertDialogHeader>
            <AlertDialogTitle className="text-lg font-semibold text-dark-text">Clear Today's Focus</AlertDialogTitle>
            <AlertDialogDescription className="text-dark-muted">
              Are you sure you want to clear all tasks from Today's Focus? This action will not delete the tasks from their projects.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel className="bg-dark-bg text-dark-text border border-dark-muted hover:bg-dark-muted transition-colors">
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={confirmClearTodayTasks}
              className="bg-dark-primary text-dark-bg hover:bg-opacity-80 transition-colors"
            >
              Clear
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}

export default FocusPanel;