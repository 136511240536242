import React, { useState } from 'react';
import Project from './Project';
import { useAppContext } from '../../context/AppContext';
import LoadingIndicator from '../LoadingIndicator';
import ErrorMessage from '../ErrorMessage';

function ProjectsContainer({ onTaskSelect, onTaskDelete,onToggleToday }) {
  const { projects, loading, error } = useAppContext();
  const [activeTab, setActiveTab] = useState('active');

  if (loading) return <LoadingIndicator />;
  if (error) return <ErrorMessage />;

  const activeProjects = projects.filter(project => project.active && !project.completed);
  const inactiveProjects = projects.filter(project => !project.active && !project.completed);
  const completedProjects = projects.filter(project => project.completed);

  const getProjectsToDisplay = () => {
    switch (activeTab) {
      case 'active':
        return activeProjects;
      case 'inactive':
        return inactiveProjects;
      case 'completed':
        return completedProjects;
      default:
        return activeProjects;
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-semibold text-dark-text">Projects</h2>
        <div className="flex space-x-2">
          {['active', 'inactive', 'completed'].map((tab) => (
            <button
              key={tab}
              className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                activeTab === tab
                  ? 'bg-dark-primary text-dark-bg'
                  : 'bg-dark-bg text-dark-muted hover:text-dark-text'
              }`}
              onClick={() => setActiveTab(tab)}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {getProjectsToDisplay().map((project) => (
        <Project 
          key={project._id} 
          project={project} 
          onTaskSelect={onTaskSelect} 
          onTaskDelete={onTaskDelete}
          onToggleToday={onToggleToday} // Add this line
        />
      ))}
      </div>
      {getProjectsToDisplay().length === 0 && (
        <p className="text-center text-dark-muted">No {activeTab} projects.</p>
      )}
    </div>
  );
}

export default ProjectsContainer;