import React, { useState } from 'react';
import { Trash2, ListPlus } from 'lucide-react';
import DeleteTaskModal from '../modals/DeleteTaskModal';
import { useTasks } from '../../hooks/useTasks';

function TaskItem({ 
  task, 
  onSelect, 
  onToggleComplete, 
  onDelete, 
  onToggleToday, 
  isDragging,
  leftContent,
  showCheckbox = true,
  isOverdue = false
}) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { toggleTodayTask } = useTasks();

  const handleToggleComplete = (e) => {
    e.stopPropagation();
    onToggleComplete(task._id, !task.completed);
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    setShowDeleteModal(true);
  };

  const confirmDelete = () => {
    onDelete(task._id);
    setShowDeleteModal(false);
  };

  const handleToggleToday = (e) => {
    e.stopPropagation();
    toggleTodayTask(task._id);
  };

  return (
    <>
      <div 
        className={`relative flex items-center bg-dark-bg p-3 rounded ${task.completed ? 'opacity-60' : ''} ${isDragging ? 'opacity-50' : ''} cursor-pointer hover:bg-dark-hover transition-colors`}
        onClick={() => onSelect(task)}
      >
        {leftContent ? (
          <div className={`flex-shrink-0 mr-3 min-w-[80px] text-sm font-medium ${isOverdue ? 'text-red-500' : 'text-dark-primary'}`}>
            {leftContent}
          </div>
        ) : showCheckbox ? (
          <div className="flex-shrink-0 mr-3">
            <div className="relative inline-block">
              <input
                type="checkbox"
                checked={task.completed}
                onChange={handleToggleComplete}
                className="appearance-none h-5 w-5 border border-dark-muted rounded-full checked:bg-dark-primary checked:border-transparent focus:outline-none focus:ring-2 focus:ring-dark-primary focus:ring-offset-2 focus:ring-offset-dark-bg transition-all duration-200 ease-in-out cursor-pointer"
                onClick={(e) => e.stopPropagation()}
              />
              {task.completed && (
                <svg className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-3 w-3 text-dark-bg pointer-events-none" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                </svg>
              )}
            </div>
          </div>
        ) : null}

        <div className="flex-grow">
          <span className="text-base text-dark-text truncate pr-16">{task.name}</span>
        </div>

        <button 
          onClick={handleToggleToday}
          className="absolute top-1/2 right-10 transform -translate-y-1/2 text-dark-muted hover:text-dark-primary transition-colors"
          aria-label={task.isToday ? "Remove from Today" : "Add to Today"}
        >
          <ListPlus size={18} className={task.isToday ? "text-dark-primary" : ""} />
        </button>

        <button 
          onClick={handleDelete}
          className="absolute top-1/2 right-3 transform -translate-y-1/2 text-dark-muted hover:text-red-500 transition-colors"
          aria-label="Delete task"
        >
          <Trash2 size={18} />
        </button>
      </div>
      <DeleteTaskModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onDelete={confirmDelete}
        taskName={task.name}
      />
    </>
  );
}

export default TaskItem;